<template>
  <div class="loginWrap">
    <div class="loginContent">
      <div class="loginLeft">
        <div class="loginLogo"></div>
        <div class="loginTitle">云中控管理平台</div>
        <div class="loginDesc">
          <p>免编程 模块化 云架构</p>
          <p>免编程：低代码平台、组件化设计</p>
          <p></p>
          <p>模块化：分布式结构、POE供电、IP控制</p>
          <p></p>
          <p>云架构：云端设计界面、云端管理工程、云端存储指令和素材</p>
          <p></p>
        </div>
      </div>
      <div class="loginForm">
        <div class="tabs">
          <div
            :class="['loginMethod', loginMethod == m.key && 'activeMethod']"
            @click="loginMethod = m.key"
            v-for="m in methods"
            :key="m.key"
          >
            <div>{{ m.text }}</div>
            <div class="line" v-if="loginMethod == m.key" />
          </div>
        </div>

        <el-form
          :model="loginMethodsModel[loginMethod]"
          :rules="formRules[loginMethod]"
          :ref="loginMethod"
          v-if="loginMethod != 'scancodeLogin'"
        >
          <el-form-item
            v-for="f in loginMethodsFormItems[loginMethod]"
            :key="f.key"
            :prop="f.key"
            :suffix-icon="f.icon"
          >
            <el-input
              v-model="loginMethodsModel[loginMethod][f.key]"
              :placeholder="f.placeholder"
              :show-password="f.key == 'password'"
            >
              <el-button
                slot="append"
                type="primary"
                v-if="f.key == 'sms_code'"
                @click="sendVerifyCode"
              >
                {{ showTimeCount ? `${timeCount}秒` : "发送验证码" }}
              </el-button></el-input
            >
          </el-form-item>

          <el-form-item>
            <el-button
              type="primary"
              class="loginBtn"
              @click="
                () => {
                  this.activeLoginRole == 'bindPhone'
                    ? this.bindPhoneFunc()
                    : this.login();
                }
              "
            >
              {{ activeLoginRole == "bindPhone" ? "绑定" : "立即登录" }}
            </el-button>

            <div class="switch" v-if="activeLoginRole != 'bindPhone'">
              <span
                @click="
                  activeLoginRole = activeLoginRole == 'user' ? 'manager' : 'user'
                "
              >
                {{ activeLoginRole == "user" ? "管理员登录" : "用户登录" }}</span
              >
            </div>
            <div style="text-align: center;">
              <el-popover
                placement="right"
                trigger="hover">
                <el-image
                  style="width: 200px;height: 200px;"
                  :src="androidImg"
                  fit="contain"
                ></el-image>
                <i slot="reference" class="iconfont icon-android" style="cursor: pointer;color:#2572dd;font-size: 0.103rem;"></i>
              </el-popover>
            </div>
          </el-form-item>
        </el-form>
        <div id="qrcode" v-else>
          <img :src="qrCodeSrc" v-if="qrCodeSrc" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MANAGER_USER_LOGIN_METHOD } from "@/constants/login";
import "./index.scss";
import { setCookie } from '@/utils/utils';
import androidImg from '@/assets/android.jpeg';
export default {
  data() {
    return {
      androidImg,
      loginMethod: "phoneNumberWay",
      loginMethodsModel: {
        passwordWay: {
          login_name: "",
          password: "",
        },
        phoneNumberWay: {
          phone: "",
          sms_code: "",
        },
        bindPhoneNumber: {
          phone: "",
          sms_code: "",
        },
      },
      loginMethodsFormItems: {
        passwordWay: [
          {
            key: "login_name",
            placeholder: "请输入用户名",
            icon: "el-icon-user",
          },
          {
            key: "password",
            placeholder: "请输入密码",
            icon: "el-icon-lock",
          },
        ],
        phoneNumberWay: [
          {
            key: "phone",
            placeholder: "请输入手机号",
            icon: "el-icon-mobile-phone",
          },
          {
            key: "sms_code",
            placeholder: "请输入验证码",
            icon: "el-icon-chat-dot-round",
          },
        ],
        bindPhoneNumber: [
          {
            key: "phone",
            placeholder: "请输入手机号",
            icon: "el-icon-mobile-phone",
          },
          {
            key: "sms_code",
            placeholder: "请输入验证码",
            icon: "el-icon-chat-dot-round",
          },
        ],
      },
      activeLoginRole: "user",
      methods: [
        {
          text: "注册/登录",
          key: "phoneNumberWay",
        },
        {
          text: "微信扫码",
          key: "scancodeLogin",
        },
      ],
      formRules: {
        passwordWay: {
          login_name: [
            { required: true, message: "请输入用户名", trigger: "blur" },
          ],
          password: [
            { required: true, message: "请输入密码", trigger: "blur" },
          ],
        },
        phoneNumberWay: {
          phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
          sms_code: [
            { required: true, message: "请输入验证码", trigger: "blur" },
          ],
        },
      },
      qrCodeSrc: "",
      showTimeCount: false,
      timeCount: 60,
      temporaryUserData: {},
    };
  },
  watch: {
    loginMethod(v) {
      if (v == "scancodeLogin") {
        this.createQrPic();
      } else {
        clearInterval(this.checkInterval);
      }
      this.initTimeCount();
    },
    activeLoginRole(v) {
      this.methods = MANAGER_USER_LOGIN_METHOD[v];
      this.loginMethod = MANAGER_USER_LOGIN_METHOD[v][0].key;
    },
  },
  methods: {
    createQrPic() {
      this.$request.getQrCode({}).then((res) => {
        if (res.status != "success") {
          this.$message.error(res.msg);
          return;
        }
        const { event_key, qrcode_url } = res.data || {};
        this.pollingQrKey = event_key;
        this.qrCodeSrc = qrcode_url;
        this.$nextTick(() => {
          this.checkInterval = setInterval(() => {
            this.$request.checkIsCodeLogin({ event_key }).then((res) => {
              if (res.status != "success") {
                return;
              }
              if (!res?.data?.phone) {
                //清除定时器
                clearInterval(this.checkInterval);
                this.activeLoginRole = "bindPhone";
                this.temporaryUserData = res.data || {};
                return;
              }
              if (res?.data?.token) {
                this.saveUserInfo(res.data || {});
                clearInterval(this.checkInterval);
              }
            });
          }, 3000);
        });
      });
    },
    getUserAuthorizedMenus(user_id) {
      this.$request
        .getUserAuthorizedMenus({
          user_id,
        })
        .then((res) => {
          if (res.status != "success") {
            this.$message.error(res.msg);
            return;
          }
          this.$store.commit("setAuthorizedMenus", {
            data: res?.data?.children || [],
          });
        });
    },
    saveUserInfo(data) {
      this.$store.commit("setLoginAccountInfo", data);
      // sessionStorage.setItem("loginAccountInfo", JSON.stringify(data));
      setCookie("loginAccountInfo", JSON.stringify(data), 7 * 24 *60 *60 *1000);

      this.getUserAuthorizedMenus(data.user_id);
    },
    login() {
      this.$request[this.loginMethod]({
        ...this.loginMethodsModel[this.loginMethod],
      }).then((res) => {
        if (res.status != "success") {
          this.$message.error(res.msg);
          return;
        }
        this.saveUserInfo(res.data || {});
      });
    },
    bindPhoneFunc() {
      this.$request
        .bindPhoneNumber({
          event_key: this.pollingQrKey,
          ...this.loginMethodsModel.bindPhoneNumber,
        })
        .then((res) => {
          if (res.status != "success") {
            this.$message.error(res.msg);
            return;
          }
          this.saveUserInfo(this.temporaryUserData);
        });
    },
    initTimeCount() {
      clearInterval(this.timeCountInterval);
      this.showTimeCount = false;
      this.timeCount = 60;
    },
    sendVerifyCode() {
      if (this.showTimeCount) return;
      this.$request
        .getLoginVerifyCode({
          phone: this.loginMethodsModel[this.loginMethod].phone,
        })
        .then((res) => {
          if (res.status != "success") {
            this.$message.error(res.msg);
            return;
          }
          this.showTimeCount = true;
          this.timeCountInterval = setInterval(() => {
            if (this.timeCount == 0) {
              this.initTimeCount();
            }
            this.timeCount = this.timeCount - 1;
          }, 1000);
          this.$message.success("发送成功");
        });
    },
  },
};
</script>

<style lang="scss">
</style>